import type { FC } from 'react';
import React, { Fragment, memo, useContext, useEffect, useState, useRef } from 'react';
import { styled } from '@compiled/react';

import { useQuery } from '@atlassian/ufo-apollo-log/use-query';

import { DEFAULT_LIMIT } from '@confluence/page-comments-queries';
import {
	VIEW_PAGE_COMMENTS_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import { LoadingPriority } from '@confluence/loadable';
import { CommentPlaceholder } from '@confluence/comment-simple/entry-points/CommentPlaceholder';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { CommentsSectionLoader } from '@confluence/comments-section';
import { useMultivariantFeatureFlag } from '@confluence/session-data';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import {
	CommentsSectionQuery,
	CommentsSectionWithoutReactionsQuery,
} from '@confluence/page-comments-queries/entry-points/CommentsSectionQuery.graphql';
import { markErrorAsHandled } from '@confluence/graphql';
import type {
	CommentsSectionQuery as CommentsSectionQueryType,
	CommentsSectionWithoutReactionsQuery as CommentsSectionWithoutReactionsQueryType,
	CommentsSectionQueryVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CommentsSectionQuery';

export const PAGE_COMMENTS_CONDITIONAL_RENDER_FF = 'confluence.frontend.delay.page.comments.render';

type PageCommentWrapperProps = {
	isDefaultWidthEnabled: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageCommentWrapper = styled.div<PageCommentWrapperProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDefaultWidthEnabled }) =>
		isDefaultWidthEnabled
			? {
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					maxWidth: '760px',
					margin: '0 auto',
				}
			: {},
);

const ViewPageFooterCommentsComponent: FC<{
	contentId: string;
	loadingPriority: LoadingPriority | null;
	classicComments?: React.ComponentType<any>;
	isCollaborativePageCommentsEnabled: boolean;
	appearance?: string;
	hasReactions?: boolean;
}> = memo(
	({
		contentId,
		classicComments,
		loadingPriority,
		isCollaborativePageCommentsEnabled,
		appearance,
		hasReactions,
	}) => {
		const ref = useRef<HTMLInputElement>(null);

		const [state] = useContentState();

		/* Preload CommentsSectionQuery - We preload CommentsSectionQuery as part of next/packages/query-preloaders/src/index.ts,
    but its bestEffort Preloading - meaning if it exceeds the threshold it wont wait for query to finish
    Adding preloading here as well to ensure data is ready once user scrolls*/

		const { error } = useQuery<
			CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType,
			CommentsSectionQueryVariables
		>(hasReactions ? CommentsSectionQuery : CommentsSectionWithoutReactionsQuery, {
			variables: {
				contentId,
				offset: '',
				first: DEFAULT_LIMIT,
			},
			fetchPolicy: 'cache-and-network',
			skip: !contentId,
		});

		/*  Marking all graphql errors here as handled since we are only preloading query. The CommentsSectionFabric component
    where the query data is used has exhaustive error handling*/

		if (error) {
			markErrorAsHandled(error);
		}

		const { cohort } = useMultivariantFeatureFlag(
			PAGE_COMMENTS_CONDITIONAL_RENDER_FF,
			['delay-render', 'not-enrolled', 'render'],
			'not-enrolled',
			true,
		);

		const delayPageCommentRender = Boolean(cohort === 'delay-render');

		const { getQueryParams } = useContext(RoutesContext);
		const experienceTracker = useContext(ExperienceTrackerContext);

		const { focusedCommentId, replyToComment, showCommentArea, editComment, commentId } =
			getQueryParams();

		/* check for query params - If anyone of these are true just load Page comments */
		const isFocusedComment = Boolean(
			focusedCommentId ||
				replyToComment ||
				showCommentArea ||
				(editComment === 'true' && commentId),
		);

		const [showPageComments, setShowPageComments] = useState(false);
		const [isCommentsReady, setIsCommentsReady] = useState(false);

		// Start Experience tracking for page comments
		useEffect(() => {
			const shouldRenderPageComments =
				isFocusedComment || !delayPageCommentRender || (delayPageCommentRender && showPageComments);

			/* only start if shouldRenderPageComments is true*/
			if (!process.env.REACT_SSR && shouldRenderPageComments) {
				experienceTracker.start({
					name: VIEW_PAGE_COMMENTS_EXPERIENCE,
					attributes: {
						isConditionalRenderingOn: delayPageCommentRender,
						isFocusedComment,
						editor: 'FABRIC',
						contentId,
					},
				});
			}
		}, [experienceTracker, contentId, isFocusedComment, delayPageCommentRender, showPageComments]);

		useEffect(() => {
			// eslint-disable-next-line no-restricted-syntax
			const observer = new IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					setShowPageComments(true);
				}
			});

			if (
				delayPageCommentRender &&
				!process.env.REACT_SSR &&
				!showPageComments &&
				!isFocusedComment &&
				state.isContentReady &&
				ref.current
			) {
				observer.observe(ref.current);
			} else {
				setShowPageComments(true);
			}
			// Remove the observer as soon as the component is unmounted
			return () => {
				observer.disconnect();
			};
		}, [delayPageCommentRender, isFocusedComment, state.isContentReady, ref, showPageComments]);

		const isDefaultWidthEnabled = appearance === `"default"` && isCollaborativePageCommentsEnabled;

		/* Display comment Placeholder if isContentReady is true - Waiting for content to render to
  display placholder since at times it will display the placeholder towards middle of screen
  and then get pushed down*/
		const renderCommentPlaceholder = () => {
			if (state.isContentReady) {
				return <CommentPlaceholder showPlaceholder />;
			}
			return null;
		};

		if (process.env.REACT_SSR) {
			return <CommentPlaceholder />;
		} else if (delayPageCommentRender) {
			/* With FF On - Conditonal Logic */
			if (isFocusedComment) {
				return (
					<CommentsSectionLoader
						contentId={contentId}
						classicComments={classicComments}
						loadingPriority={loadingPriority || LoadingPriority.PAINT}
					/>
				);
			} else {
				return (
					<PageCommentWrapper
						data-testid={
							isDefaultWidthEnabled ? `page-comment-wrapper-default` : 'page-comment-wrapper'
						}
						isDefaultWidthEnabled={isDefaultWidthEnabled}
						ref={ref}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="page-comment-wrapper"
					>
						{showPageComments ? (
							<Fragment>
								{!isCommentsReady && renderCommentPlaceholder()}
								<CommentsSectionLoader
									contentId={contentId}
									classicComments={classicComments}
									handlePageCommentsRender={() => setIsCommentsReady(true)}
									loadingPriority={loadingPriority || LoadingPriority.PAINT}
								/>
							</Fragment>
						) : (
							renderCommentPlaceholder()
						)}
					</PageCommentWrapper>
				);
			}
		} else {
			/* With FF Off - Old Logic */
			return (
				<PageCommentWrapper
					data-testid={
						isDefaultWidthEnabled ? `page-comment-wrapper-default` : 'page-comment-wrapper'
					}
					isDefaultWidthEnabled={isDefaultWidthEnabled}
					ref={ref}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="page-comment-wrapper"
				>
					<CommentsSectionLoader
						contentId={contentId}
						classicComments={classicComments}
						loadingPriority={
							isFocusedComment ? LoadingPriority.PAINT : loadingPriority || LoadingPriority.PAINT
						}
					/>
				</PageCommentWrapper>
			);
		}
	},
);

export const ViewPageFooterComments = withErrorBoundary({
	attribution: Attribution.COLLABORATION,
})(ViewPageFooterCommentsComponent);
