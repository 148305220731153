import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N40 } from '@atlaskit/theme/colors';

import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import type { ReactionLocation } from '@confluence/reactions';
import {
	DelayedReactions,
	Reactions,
	useReactionsQueryParams,
	ReactionContainerType,
} from '@confluence/reactions';
import { ReactionsPlaceholder } from '@confluence/reactions/entry-points/ReactionsPlaceholder';
import { ReactionsContext } from '@confluence/comment-context';

type ViewPageReactionsProps = {
	isDefaultWidthEnabled: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewPageReactionsContainer = styled.div<ViewPageReactionsProps>({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	padding: `${token('space.250', '20px')} 0`,
	clear: 'both',
	borderBottom: `1px solid ${token('color.border', N40)}`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.labelsSection': {
		maxWidth: '50%',
		minWidth: '20%',
		float: 'right',
		display: 'inline-block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: (props) => (props.isDefaultWidthEnabled ? 'hidden' : 'visible'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: (props) => (props.isDefaultWidthEnabled ? '760px' : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) => (props.isDefaultWidthEnabled ? '0 auto' : 'unset'),
});

export const ViewPageReactions = ({
	hasReactions,
	appearance,
	isCollaborativePageCommentsEnabled,
	contentType,
	contentId,
	spaceId,
	contentSubType,
}) => {
	const [state] = useContentState();

	const { focusedPageReactionsId } = useReactionsQueryParams();
	const { isReactionsEnabled } = useContext(ReactionsContext);

	const displayReactions = () => {
		if (contentType && spaceId) {
			// We want to load in the background ONLY if we have a query param, otherwise we can preload the query
			const ReactionsLoader = Boolean(focusedPageReactionsId) ? DelayedReactions : Reactions;

			return (
				<ReactionsLoader
					contentId={contentId}
					location={contentType as ReactionLocation}
					containerId={spaceId}
					containerType={ReactionContainerType.SPACE}
					contentSubType={contentSubType}
				/>
			);
		} else {
			return <ReactionsPlaceholder />;
		}
	};
	const isDefaultWidthEnabled = appearance === `"default"` && isCollaborativePageCommentsEnabled;
	if (process.env.REACT_SSR) {
		return (
			<ViewPageReactionsContainer
				id="view-page-reactions-container"
				data-test-id="view-page-reactions-container"
				isDefaultWidthEnabled={isDefaultWidthEnabled}
			>
				{isReactionsEnabled && hasReactions && <ReactionsPlaceholder useAccuratePills />}
			</ViewPageReactionsContainer>
		);
	} else if (state?.isContentReady) {
		return (
			<ViewPageReactionsContainer
				id="view-page-reactions-container"
				data-test-id="view-page-reactions-container"
				isDefaultWidthEnabled={isDefaultWidthEnabled}
			>
				{hasReactions && isReactionsEnabled && displayReactions()}
			</ViewPageReactionsContainer>
		);
	}

	return null;
};
