import { LoadablePaint, LoadableLazy, MustPreloadLoadableNames } from '@confluence/loadable';

export { LivePageComponentServer } from './LivePageComponentServer';

export const LivePageComponent = LoadablePaint({
	name: MustPreloadLoadableNames.LivePageComponentLoader,
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LivePageComponent" */ '../src/LivePageComponent'))
			.LivePageComponent,
});

export const LivePagesChangeboardingManager = LoadableLazy({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-LivePagesChangeboardingManager" */ './LivePagesChangeboardingManager'
			)
		).LivePagesChangeboardingManager;
	},
});
