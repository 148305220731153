import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';

import type { PositionalComment } from '@confluence/comment-context';

import { ArrowContainer, NewCommentsPill, PillContainer } from './styled-components';

type CommentsPillProps = {
	isTop?: boolean;
	offset?: number;
	handleClick: () => void;
	comments: PositionalComment[];
	name: string;
};

export const CommentsPill = ({ isTop, offset, handleClick, comments, name }: CommentsPillProps) => (
	<PillContainer isTop={isTop} offset={offset} data-test-id={name.concat('-container')}>
		{comments.length > 0 && (
			<NewCommentsPill onClick={handleClick} data-testid={name}>
				<FormattedMessage
					id="page-comments.new.comments"
					defaultMessage="{numNewComments, plural,
          one {1 new page comment}
          =2 {# new page comments}
          =3 {# new page comments}
          =4 {# new page comments}
          other {5+ new page comments}}"
					description="A button informing the user there are new page comments"
					values={{
						numNewComments: comments.length,
					}}
				/>
				<ArrowContainer>
					{isTop ? (
						<ArrowUpIcon label="arrow-up" size="small" />
					) : (
						<ArrowDownIcon label="arrow-down" size="small" />
					)}
				</ArrowContainer>
			</NewCommentsPill>
		)}
	</PillContainer>
);
