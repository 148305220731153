import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import {
	GuestSatisfactionSurveyWrapper,
	GuestSatisfactionSurveyStateContainer,
} from '@confluence/external-collab-ui';
import { EditorPublishSuccessFlag } from '@confluence/onboarding-editor';

type SatisfactionSurveysProps = {
	contentId: string;
	isEditorExpEnabled: boolean;
};

export const SatisfactionSurveys = ({
	contentId,
	isEditorExpEnabled,
}: SatisfactionSurveysProps) => (
	<Subscribe to={[GuestSatisfactionSurveyStateContainer]}>
		{({ state: guestSurveyState, resetTrigger }: GuestSatisfactionSurveyStateContainer) => {
			const { isSurveyTriggered: isGuestSurveyTriggered, source: guestSurveySource } =
				guestSurveyState;

			// We want the Guest survey to have priority over the Editor survey. But just because one of
			// the trigger actions for the Guest survey has occurred, that doesn't mean it will be shown.
			// The GuestSatisfactionSurveyWrapper needs to check if this user is a guest, if they have
			// submitted the survey already, etc.  So we will pass the correct flavor of the Editor
			// survey as a child to the GuestSatisfactionSurveyWrapper and if the Guest survey is
			// triggered but the wrapper decides it doesn't need to show the Guest survey or there hasn't
			// been a trigger for the Guest survey, then the wrapper will render its children
			return (
				<GuestSatisfactionSurveyWrapper
					isGuestSurveyTriggered={isGuestSurveyTriggered}
					resetGuestSurveyTrigger={resetTrigger}
					source={guestSurveySource}
				>
					{isEditorExpEnabled && (
						// this determines whether to show the success flag based on our FFs
						<EditorPublishSuccessFlag contentId={contentId} />
					)}
				</GuestSatisfactionSurveyWrapper>
			);
		}}
	</Subscribe>
);
