import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import { P300 } from '@atlaskit/theme/colors';

import type { FlagsStateContainer, FlagDescriptor } from '@confluence/flags';
import {
	ONBOARDING_EDITOR_PUBLISH_SUCCESS_FLAG_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { EDITOR_ONBOARDING_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { CoreInvitesContext, CoreInvitesSource } from '@confluence/core-invites-provider';

import { useEditorOnboardingEvents } from './EditorOnboardingAnalytics';

const i18n = defineMessages({
	// EO2: Publish Success Flag copy
	editorTutorialFlagTitle: {
		id: 'onboarding-editor.tutorial.success.flag.title',
		defaultMessage: 'Page published to your personal space',
		description: 'Title for Editor Tutorial success flag',
	},
	editorTutorialFlagContent: {
		id: 'onboarding-editor.tutorial.success.flag.content',
		defaultMessage:
			'Congrats on finishing the editor tutorial! {emoji} {br}We’ve published this page to your personal space so you can return to it anytime.',
		description: 'Content for Editor Tutorial success flag',
	},
	// Contextual Invite copy
	contextualInviteFlagTitle: {
		id: 'onboarding-editor.contextual-invite.title',
		defaultMessage: 'Next step: Share your work',
		description: 'Title for Contextual Invite flag',
	},
	contextualInviteFlagContent: {
		id: 'onboarding-editor.contextual-invite.content',
		defaultMessage: 'Send an invite to a teammate to start collaborating on your new page.',
		description: 'Content for Contextual Invite flag',
	},
	contextualInviteFlagAction: {
		id: 'onboarding-editor.contextual-invite.action',
		defaultMessage: 'Invite people to Confluence',
		description: 'Copy for Contextual Invite flag action',
	},
});

export const EDITOR_TUTORIAL_SUCCESS_FLAG_ID = 'onboarding-editor.tutorial.success.flag';
export const ONBOARDING_INVITE_FLAG_ID = 'onboarding.contextual.invite.flag';
export const XFLOW_NUDGE_INVITE_TEAM_FLAG_ID = 'xflow.nudge.invite.team.flag';

const CONTEXTUAL_INVITE_FLAG_TYPE = 'contextualInviteFlag';
const EDITOR_TUTORIAL_FLAG_TYPE = 'editorDraft';

export const EditorPublishSuccessFlag = ({
	flags,
	showEditorTutorialPublishSuccessFlag = false,
}: {
	flags: FlagsStateContainer;
	showEditorTutorialPublishSuccessFlag?: boolean;
}) => {
	const { setOnboardingState } = useOnboardingState();
	const experienceSucceededRef = useRef(false);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { openCoreInvites } = useContext(CoreInvitesContext);
	const { fireEditorPublishSuccessFlagViewed } = useEditorOnboardingEvents();

	const dismissFlag = (id: string) => {
		if (!experienceSucceededRef.current) {
			experienceTracker.succeed({
				name: ONBOARDING_EDITOR_PUBLISH_SUCCESS_FLAG_EXPERIENCE,
			});
			experienceSucceededRef.current = true;
		}

		void flags.hideFlag(id);
	};

	const flagId = useMemo(() => {
		if (showEditorTutorialPublishSuccessFlag) {
			return EDITOR_TUTORIAL_FLAG_TYPE;
		}
		return CONTEXTUAL_INVITE_FLAG_TYPE;
	}, [showEditorTutorialPublishSuccessFlag]);

	const id = useMemo(() => {
		if (showEditorTutorialPublishSuccessFlag) {
			return EDITOR_TUTORIAL_SUCCESS_FLAG_ID;
		}
		return ONBOARDING_INVITE_FLAG_ID;
	}, [showEditorTutorialPublishSuccessFlag]);

	useEffect(() => {
		const timer = setTimeout(() => {
			dismissFlag(id);
		}, 8000);

		experienceTracker.start({
			name: ONBOARDING_EDITOR_PUBLISH_SUCCESS_FLAG_EXPERIENCE,
		});

		fireEditorPublishSuccessFlagViewed(flagId);
		setSuccessFlagSeen();
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setSuccessFlagSeen = () => {
		void setOnboardingState({
			key: showEditorTutorialPublishSuccessFlag
				? EDITOR_ONBOARDING_STATE_KEYS.EDITOR_TUTORIAL_SUCCESS_FLAG_SEEN
				: EDITOR_ONBOARDING_STATE_KEYS.SUCCESS_FLAG_SEEN,
			value: 'true',
		});
	};

	const editorTutorialSuccessFlag: FlagDescriptor = {
		id: EDITOR_TUTORIAL_SUCCESS_FLAG_ID,
		type: 'success-circle',
		title: <FormattedMessage {...i18n.editorTutorialFlagTitle} />,
		description: (
			<FormattedMessage
				{...i18n.editorTutorialFlagContent}
				values={{
					emoji: '\uD83E\uDD73',
					br: <br />,
				}}
			/>
		),
		onClose: () => {
			// Change onboarding state to only show the banner once
			dismissFlag(EDITOR_TUTORIAL_SUCCESS_FLAG_ID);
		},
	};

	const inviteSuccessFlag: FlagDescriptor = {
		id: ONBOARDING_INVITE_FLAG_ID,
		type: 'custom',
		customIcon: (
			<PeopleGroupIcon
				label="people-group-icon"
				primaryColor={token('color.icon.discovery', P300)}
			/>
		),
		title: <FormattedMessage {...i18n.contextualInviteFlagTitle} />,
		description: <FormattedMessage {...i18n.contextualInviteFlagContent} />,
		actions: [
			{
				content: <FormattedMessage {...i18n.contextualInviteFlagAction} />,
				onClick: () => {
					openCoreInvites?.(CoreInvitesSource.ONBOARDING_INVITE_FLAG);
					dismissFlag(ONBOARDING_INVITE_FLAG_ID);
				},
			},
		],
	};

	useEffect(() => {
		if (showEditorTutorialPublishSuccessFlag) {
			void flags.showFlag(editorTutorialSuccessFlag);
		} else {
			void flags.showFlag(inviteSuccessFlag);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
