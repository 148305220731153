import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { RelatedEntityType } from '@atlassian/search-ai/related-entities-api';

import { ContentAppearanceType } from '@confluence/content-appearance';
import { useSessionData } from '@confluence/session-data';
import { LoadableAfterPaint } from '@confluence/loadable';

const aiTopicsContainerStyles = xcss({
	margin: '0 auto',
	width: '100%',
});

const narrowStyles = xcss({
	maxWidth: '760px',
});

const wideStyles = xcss({
	maxWidth: 'unset',
});

const contentToEntityMap = {
	blogpost: RelatedEntityType.CONFLUENCE_BLOGPOST,
	page: RelatedEntityType.CONFLUENCE_PAGE,
};

type RelatedTopicsProps = {
	pageWidthType: string;
	entityId: string;
	contentType: string;
};

const LazyAITopics = LoadableAfterPaint({
	name: 'AITopics',
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AITopics" */ '@atlassian/search-ai/ai-topics'))
			.AITopics,
	loading: () => null,
});

export const RelatedTopics = ({ pageWidthType, entityId, contentType }: RelatedTopicsProps) => {
	const { cloudId, displayName, userId } = useSessionData();

	const principalEntityType = contentToEntityMap[contentType];

	if (!principalEntityType) return null;

	const isDefaultWidth = JSON.parse(pageWidthType) === ContentAppearanceType.DEFAULT;

	return (
		<Box
			xcss={[aiTopicsContainerStyles, isDefaultWidth ? narrowStyles : wideStyles]}
			key={`${entityId}-ai-topics}`}
		>
			<LazyAITopics
				entityId={entityId}
				principalEntityType={principalEntityType}
				cloudId={cloudId}
				userName={displayName ?? ''}
				userAccountId={userId ?? ''}
			/>
		</Box>
	);
};
